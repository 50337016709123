import { Auth } from "aws-amplify";

export default async function AmazonConfig() {
    const baseCognitoProviderUrl = process.env.REACT_APP_SERVICEPROVIDERSTACK_PARAMETER_SERVICEPROVIDERAPIURL;
    const baseCognitoProviderPublicUrl = process.env.REACT_APP_SERVICEPROVIDERSTACK_PARAMETER_SERVICEPROVIDERPUBLICAPIURL;
    const ProviderApi = `${baseCognitoProviderUrl}api/serviceprovider`;
    const ReportUploadAPI = `${baseCognitoProviderUrl}api/Reports/getUploadUrl`;
    const ProviderPublicApi = `${baseCognitoProviderPublicUrl}api/serviceprovider`;

    const amazonConfig = {
        Auth: {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,

            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_REGION,

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_USERPOOL_ID,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: process.env.REACT_APP_WEBCLIENT_ID,

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,
        },
        API: {
            endpoints: [
                {
                    name: "ProviderApi",
                    endpoint: ProviderApi,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    }),
                },
                {
                    name: "ServiceRequest",
                    endpoint: `${baseCognitoProviderUrl}api/ServiceRequest`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    }),
                },
                {
                    name: "ReportUploadAPI",
                    endpoint: ReportUploadAPI,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    }),
                },
                {
                    name: "ProviderPublicApi",
                    endpoint: ProviderPublicApi,
                },
            ],
        },
        geo: {
            AmazonLocationService: {
                maps: {
                    items: {
                        "ServiceproviderStack-ServiceProvider-Map": {
                            // REQUIRED - Amazon Location Service Map resource name
                            style: "VectorEsriStreets", // REQUIRED - String representing the style of map resource
                        },
                    },
                    default: "ServiceproviderStack-ServiceProvider-Map", // REQUIRED - Amazon Location Service Map resource name to set as default
                },
                search_indices: {
                    items: ["ServiceproviderStack-ServiceProvider-PlaceIndex"], // REQUIRED - Amazon Location Service Place Index name
                    default: "ServiceproviderStack-ServiceProvider-PlaceIndex", // REQUIRED - Amazon Location Service Place Index name to set as default
                },
                region: "us-east-1", // REQUIRED - Amazon Location Service Region
            },
        },
    };

    return amazonConfig;
}
