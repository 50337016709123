import {
    Box,
    Grid, List,
    ListItem, Typography,
    useTheme,
    CircularProgress,
    Divider,
    Stack,
    Alert,
} from "@mui/material";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Room } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import NoImage from "../../assets/no-image.svg";

function TempReportForm({ RoomsToUpload, ServiceRequestId, ServiceReportFormLoadingSetter }) {
    const [GetListofServices, setGetListofServices] = useState([]);
    const [GetserviceDetailsbyId, setServiceDetailsbyId] = useState();
    const theme = useTheme();

    const roomDetails = RoomsToUpload.map((obj) => ({ ...obj, isUploading: false, originalUrl: obj.imgURL, isSuccess: false, isError: false }));
    const [PropertyRooms, setPropertyRooms] = useState(roomDetails);

    console.log("PropertyRooms Init:");
    console.log(roomDetails);

    function setImageLoadingState(index, bool) {
        console.log(`Setting image for Index ${index}, Bool: ${bool}`);

        const items = [...PropertyRooms];
        items[index].isUploading = bool;

        if (bool) {
            items[index].isError = false;
            items[index].isSuccess = false;
        }

        setPropertyRooms(items);

        if (bool || (items.includes((x) => x.isUploading === true))) {
            ServiceReportFormLoadingSetter(true);
        } else {
            ServiceReportFormLoadingSetter(false);
        }
    }

    function setUploadResult(index, isSuccess) {
        setImageLoadingState(index, false);

        const items = [...PropertyRooms];
        items[index].isSuccess = isSuccess;
        items[index].isError = !isSuccess;

        setPropertyRooms(items);
    }

    function setImage(index, url) {
        console.log(`Setting image for Index ${index}, Url: ${url}`);

        const items = [...PropertyRooms];
        items[index].originalUrl = items[index].imgURL;
        items[index].imgURL = url;
        setPropertyRooms(items);
    }

    function uploadFile(uploadUrl, file, index) {
        fetch(uploadUrl, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": file.type,
            },
        }).then((e1) => {
            // Upload Successful
            console.log(`File Uploaded to ${uploadUrl}`);
            console.log(e1);
            setUploadResult(index, true);
            return true;
        }).catch((e2) => {
            // Upload Failed
            console.log(`File Upload Failed to ${uploadUrl}`);
            console.log(e2);
            setUploadResult(index, false);
        });

        return false;
    }

    const onImageFileChange = (event, index) => {
        const file = event.target.files[0];
        const fileDisplayUrl = URL.createObjectURL(event.target.files[0]);

        if (file) {
            setImage(index, fileDisplayUrl);
            setImageLoadingState(index, true);

            // Verify if it's a file
            const propertyRoomDetails = PropertyRooms[index];

            const fileNameParts = file.name.split(".");
            const fileExtension = fileNameParts[fileNameParts.length - 1];

            API.get("ReportUploadAPI", "", {
                queryStringParameters: {
                    FileExtension: fileExtension,
                    RoomFK: propertyRoomDetails.roomFK,
                    ServiceRequestId,
                },
            }).then((res) => {
                uploadFile(res.uploadUrl, file, index);
            }).catch((ex) => {
                // Failure on getting presigned url to get file
                alert("Upload image failed!");
                console.log("Upload image failed!");
                console.log(ex);
                setImageLoadingState(index, false);
            });
        }
    };

    return (
        <List
            sx={{
                overflow: "auto",
                // maxHeight: 300,
            }}
        >
            {PropertyRooms.map((item, index) => {
                const idx = index;
                const imgURL = (item.imgURL === undefined || item.imgURL === "") ? NoImage : item.imgURL;

                return (
                    <ListItem key={idx}>
                        <Grid container marginBottom={5} sx={{ height: theme.spacing(20) }}>
                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        backgroundImage: `url(${imgURL})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        height: theme.spacing(20),
                                    }}
                                >

                                    {
                                        item.isUploading && (<CircularProgress />)
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} alignItems="center">
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={0.5}
                                >
                                    <Typography variant="body1">
                                        {item.roomLabel}
                                    </Typography>
                                    { item.isUploading && (<CircularProgress size={20} />) }
                                    { item.isSuccess && (<CloudDoneIcon color="success" />) }
                                    { item.isError && (<ErrorIcon color="error" />) }

                                </Stack>

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => onImageFileChange(e, idx)}
                                    disabled={item.isUploading}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default TempReportForm;
