import { Box, Button, Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, List, ListItem, ListItemButton, Paper, Radio, RadioGroup, Skeleton, Stack, Tab, TextField, Typography, useTheme } from "@mui/material";
import { API, Geo } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { text } from "@fortawesome/fontawesome-svg-core";
import { ServiceTypes } from "../utils/enum";
import { setUserInfo, showLoader } from "../features/auth/authSlice";
import CustomLocationFormGroup from "./helper/CustomLocationFormGroup";
import { useUpdateProfileMutation } from "../features/auth/spAPI";

export default function Profile({ callbackSave }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [locSearch, setLocSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [profile, setProfile] = useState({
        firstName: "",
        lastName: "",
        locationLong: "",
        locationLat: "",
        address: "",
        timeZone: "",
        exclusivity: 1,
        servicesOffered: [],
    });
    const [servicesOffered, setServicesOffered] = useState([]);

    const [searchLoading, setSearchLoading] = useState(false);

    const TAB_PROFILE = "1";
    const TAB_ACCOUNT = "2";
    const [selectedTab, setSelectedTab] = useState(TAB_PROFILE);

    const [updateProfile, {
        isLoading: isLoadingUpdateProfile,
        isSuccess: isSuccessUpdateProfile,
        isError: isErrorUpdateProfile,
        error: errorUpdateProfile,
    }] = useUpdateProfileMutation();

    useEffect(() => {
        if (userInfo.firstName !== "") {
            const arrServices = [];
            userInfo.servicesOffered.forEach((e) => {
                arrServices.push(e);
            });

            setProfile((prev) => ({
                ...prev,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                address: userInfo.address,
                locationLat: userInfo.locationLat,
                locationLong: userInfo.locationLong,
                timeZone: userInfo.timeZone,
                exclusivity: userInfo.exclusivity,
                servicesOffered: arrServices,
            }));

            const soArr = [];
            ServiceTypes.forEach((so) => {
                const soItem = {
                    type: so.type,
                    value: so.value,
                    checked: userInfo.servicesOffered.find((x) => x === so.value) !== undefined,
                };
                soArr.push(soItem);
            });

            setServicesOffered(soArr);
        } else {
            const soArr = [];
            ServiceTypes.forEach((so) => {
                const soItem = {
                    type: so.type,
                    value: so.value,
                    checked: false,
                };
                soArr.push(soItem);
            });
            setServicesOffered(soArr);
        }
    }, [userInfo]);

    useEffect(() => {
        const delayDbounceFn = setTimeout(() => {
            if (locSearch.trim().length > 0) {
                setSearchLoading(true);
                const searchOptions = {
                    maxResults: 5,
                };
                Geo.searchByText(locSearch, searchOptions)
                    .then((d) => {
                        setSearchResult(d);
                        setSearchLoading(false);
                    }).finally(() => setSearchLoading(false));
            }
        }, 3000);
        return () => clearTimeout(delayDbounceFn);
    }, [locSearch]);

    const handleSearchLocation = (e) => {
        setLocSearch(e.target.value);
        setProfile((prev) => ({ ...prev, address: e.target.value }));
    };

    const handleSelectServiceType = (data) => (e) => {
        const soIndex = servicesOffered.findIndex((x) => x.value === data);
        servicesOffered[soIndex].checked = e.target.checked;

        const arr = profile.servicesOffered;

        if (e.target.checked) {
            arr.push(data);
        } else {
            const index = arr.indexOf(data);
            arr.splice(index, 1);
        }

        setProfile((prev) => ({
            ...prev,
            servicesOffered: arr,
        }));
    };

    const hasAtLeastOneServiceType = () => servicesOffered.filter((x) => x.checked === true);

    const isProfileComplete = () => (profile.address.trim().length > 0
        && profile.firstName.trim().length > 0
        && profile.lastName.trim().length > 0
        && profile.locationLat !== 0
        && profile.locationLong !== 0
        && profile.timeZone.trim().length > 0
        && hasAtLeastOneServiceType().length > 0);

    const handleUpdateProfile = async () => {
        // dispatch(showLoader({ open: true, message: "Updating your profile. Please wait..." }));
        const myInit = {
            body: profile,
        };

        await updateProfile({
            profile,
        });
        // await API.post("ProviderApi", "/signUpProfileUpdate", myInit)
        //     .then((ret) => {
        //         // console.log(r);
        //         dispatch(setUserInfo(ret));
        //     })
        //     .catch((e) => console.log("error", e));

        // dispatch(showLoader({ open: false, message: "" }));

        if (callbackSave !== undefined) { callbackSave(); }
    };

    useEffect(() => {
        if (isSuccessUpdateProfile) {
            // if this is from complete-registration page
            // redirect to dashboard
            // else do nothing
        }
    }, [isSuccessUpdateProfile]);

    return (
        <Paper>
            <TabContext value={selectedTab}>
                <TabList onChange={(e, val) => { setSelectedTab(val); }}>
                    <Tab label="Profile" value={TAB_PROFILE} />
                    <Tab label="Service Offered" value={TAB_ACCOUNT} />
                </TabList>
                <TabPanel value={TAB_PROFILE}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={!(profile.firstName.trim().length > 0)}
                                label="First Name"
                                size="small"
                                fullWidth
                                focused
                                value={profile.firstName}
                                onChange={(e) => setProfile((prev) => ({ ...prev, firstName: e.target.value }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={!(profile.lastName.trim().length > 0)}
                                label="Last Name"
                                size="small"
                                fullWidth
                                focused
                                value={profile.lastName}
                                onChange={(e) => setProfile((prev) => ({ ...prev, lastName: e.target.value }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomLocationFormGroup
                                addressProp={profile.address}
                                timeZoneProp={profile.timeZone}
                                callbackResult={(res) => {
                                    console.log(">>", res.Address);
                                    console.log(">>", res.mapLocation);

                                    setProfile((prev) => ({
                                        ...prev,
                                        address: res.Address,
                                        timeZone: res.mapLocation.timeZone,
                                        locationLat: JSON.stringify(res.mapLocation.latitude),
                                        locationLong: JSON.stringify(res.mapLocation.longitude),
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={TAB_ACCOUNT}>
                    {/* <Paper
                        variant="outlined"
                        sx={{
                            padding: theme.spacing(2),
                        }}
                    > */}
                    {/* <Typography
                            component="div"
                            variant="body2"
                            color="text.secondary"
                            mb={theme.spacing(1)}
                        >
                            Services Offered:
                        </Typography> */}
                    <FormControl
                        error={hasAtLeastOneServiceType().length === 0}
                        component="fieldset"
                        variant="standard"
                        fullWidth
                    >
                        <FormGroup>
                            <Typography
                                component="div"
                                variant="body2"
                                mb={theme.spacing(1)}
                                color="text.secondary"
                            >
                                Please select atleast 1 service
                            </Typography>
                            <Grid container>
                                {servicesOffered.map((so) => (
                                    <Grid key={so.value} item xs={12} sm={6}>
                                        <FormControlLabel
                                            label={so.type}
                                            checked={so.checked}
                                            control={(
                                                <Checkbox
                                                    size="small"
                                                    onChange={handleSelectServiceType(so.value)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                    {/* </Paper> */}
                    <Paper
                        variant="outlined"
                        sx={{
                            padding: theme.spacing(2),
                            marginTop: theme.spacing(3),
                        }}
                    >
                        <Typography
                            component="div"
                            variant="body2"
                            mb={theme.spacing(1)}
                            color="text.secondary"
                        >
                            How would you like to recieve services requests from:
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                value={profile.exclusivity}
                                onChange={(e) => {
                                    setProfile((prev) => ({
                                        ...prev,
                                        exclusivity: parseInt(e.target.value, 10),
                                    }));
                                }}
                            >
                                <FormControlLabel value={0} control={<Radio />} label="Any host in Hostly. I want to be listed in Hostly search results." />
                                <FormControlLabel value={1} control={<Radio />} label="Only hosts that invite me. I do not want to be listed in Hostly search results." />
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </TabPanel>
            </TabContext>
            <Box
                sx={{
                    textAlign: "right",
                    marginRight: theme.spacing(3),
                    paddingBottom: theme.spacing(3),
                }}
            >
                <Button
                    error={profile.servicesOffered.length === 0 ? "true" : "false"}
                    variant="contained"
                    disabled={!isProfileComplete()}
                    onClick={handleUpdateProfile}
                >
                    Save
                </Button>
            </Box>
        </Paper>
    );
}
