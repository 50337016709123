import { Card, CardContent, LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { Hub } from "aws-amplify";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import AppRoute from "./AppRoute";

function App() {
    const theme = useTheme();

    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            console.log("App", event, data);
            switch (event) {
                case "autoSignIn":
                    window.location.replace("/complete-registration");
                    break;
                case "signIn":
                    break;
                case "signOut":
                    window.location.replace("/");
                    break;

                default:
                    break;
            }
        });
    }, []);

    const loader = () => (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            pt={theme.spacing(16)}
            px={theme.spacing(8)}
        >
            <Card>
                <CardContent>
                    <Typography
                        color="primary"
                        variant="h6"
                        align="center"
                    >
                        Please wait...
                    </Typography>
                    <LinearProgress />
                </CardContent>
            </Card>
        </Stack>
    );

    return (
        <RouterProvider
            router={AppRoute}
            fallbackElement={loader()}
        />
    );
}

export default App;
